import React, { useState } from "react";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import MapComponent from "./components/MapDisplay";

export const DateRangePickerer = ({ onRangeSelect }) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const handleSelect = (ranges) => {
    setState([ranges.selection]);
    if (onRangeSelect) {
      onRangeSelect(ranges.selection);
    }
  };

  return (
    <div className={"xlight-box-shadow"}>
      <MapComponent />
    </div>
  );
};
