import "./style.css";
import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

export const DateRangePickerOverlay = (props) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleSelect = (ranges) => {
    setState([ranges.selection]);
    props.setter(ranges.selection);
  };

  return (
    <div
      className={"xlight-box-shadow"}
      style={{ marginBottom: "10px" }}
      ref={props.ref}
    >
      <span
        className=" width-100 bold-1 color-white"
        style={{
          display: "block",
          padding: "5px 10px",
          width: "100%",
          fontSize: "12px",
          background: "#b79800",
          borderRadius: "5px 5px 0px 0px",
          marginBottom: "4px",
        }}
      >
        Select Date
      </span>
      <DateRangePicker
        ranges={state}
        rangeColors={["#c6c6c6"]}
        onChange={handleSelect}
      />
    </div>
  );
};
