export function removeFromArray(value, array) {
  const index = array.indexOf(value);
  if (index > -1) {
    // only splice array when item is found
    array.splice(index, 1); // 2nd parameter means remove one item only
  }
  return array;
}
export function isArraySubset(array1, array2) {
  // Create a Set from array2 to improve lookup time
  const set2 = new Set(array2);

  // Check if every element in array1 exists in set2

  return array1.every((element) => set2.has(element));
}
export function addCommasToNumber(number) {
  if (!number) {
    return number;
  }
  // Convert the number to a string
  const numberString = number.toString();

  // Split the number into integer and decimal parts
  const [integerPart, decimalPart] = numberString.split(".");

  // Format the integer part with commas
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  // Recombine the integer and decimal parts
  return false
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;
}
