import { useState } from "react";
import "./style.css";
import { MdCancel } from "react-icons/md";

export default function ChoiceButton(props) {
  const [active, setActive] = useState(false);
  function toggle() {
    setActive((prev) => !prev);
  }
  return (
    <div className={"contain-btn center-hor space-around relative"}>
      {!active ? (
        <span className="main-btn" onClick={toggle}>
          Add New Work Order
        </span>
      ) : (
        <div className="float-option-btns center-hor space-around">
          <span className="option-btn">Funeral Order</span>
          <span className="option-cancle-btn absolute-center" onClick={toggle}>
            <MdCancel className="icon mid red" />
          </span>
          <span className="option-btn">Retail Order</span>
        </div>
      )}
    </div>
  );
}
