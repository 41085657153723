import axios from "axios";
import { useState, useEffect } from "react";
import { MdNotifications } from "react-icons/md";
import { BASE_SERVER_URL } from "../../sampleData";
import moment from "moment";

export default function NotificationBoard(props) {
  const [errors, setErrors] = useState([]);
  useEffect(() => {
    let url = BASE_SERVER_URL + "/error-handler/get-all";
    axios.get(url).then((doc) => {
      setErrors(doc.data);
    });
  });

  return (
    <div className="notification-board">
      <div className="head">Alerts and Notifications</div>
      {(errors || []).map((each) => {
        return <NotificationBar type="red" info={each} />;
      })}
    </div>
  );
}

function NotificationBar(props) {
  return (
    <div className="notif-bar width-100 center-hor">
      <span className={"notif-icon absolute-center width-10 " + props.type}>
        <MdNotifications className="icon large white" />
      </span>
      <div className="width-90 center-hor space-between">
        <span className="notif-text font-medium text-left">
          {(props.info || {}).message}
        </span>
        <span className="notif-text font-small text-gold">
          {moment((props.info || {}).date).format("LL")}
        </span>
      </div>
    </div>
  );
}
