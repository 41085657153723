import "./style.css";
import React, { useEffect, useState } from 'react';
import { PopUpAlert } from '../../../components/movableDragDropArea';
import { TextInputField, ButtonFlat, toName } from "../../../components";

import { TaggedList } from "../../../components/TaggedList";
import { BASE_SERVER_URL } from "../../../sampleData";
import axios from "axios";

export default function FlowersList(props) {
    const [newFlower, setNewFlower] = useState({flower_name: ""});
    const [flowers, setFlowers] = useState([]);
    const [alert, setAlert] = useState({});
    const [edit, setEdit] = useState(false);
    const [editLoading, setEditLoading] = useState(false);

    function editFlowers() {
      setEditLoading(true)
      if (!Boolean(newFlower.flower_name)) {
        return false;
      }
      let url = edit ? `${BASE_SERVER_URL}/api/flowers/update/`+newFlower._id : `${BASE_SERVER_URL}/api/flowers/add-new`;
      axios[edit ? 'put' : 'post'](url, newFlower).then(res => {
        if (res.data.success) {
          fetchFlowers();
          setNewFlower({flower_name: ""});
          setEdit(false);
          setEditLoading(false)
            }
        }).catch(err => {
            console.log(err);
        })
    }

    function deleteFlowers(id) {
        axios.delete(`${BASE_SERVER_URL}/api/flowers/delete/`+id).then(res => {
            if (res.data.success) {
               fetchFlowers();
                setNewFlower({});
                setAlert({})
            }
        }).catch(err => {
            console.log(err);
        })
    }

    function fetchFlowers() {
        axios.get(`${BASE_SERVER_URL}/api/flowers/get-all`).then(res => {   
            if (res.data.success) {
                setFlowers(res.data.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        fetchFlowers();
    }, []);
    
    return (
    <div className="col-md-4 col-sm-12 block">
          {Object.keys(alert).length > 0 && (
        <PopUpAlert
          info={alert}
          onClose={() => {
            setAlert({});
          }}
        />
      )}
          <header className="golden-card">Content Flowers</header>
          <div className="width-100 center-hor space-between">
            <div className="width-70">
              <TextInputField
                addClass="width-100 height-thin flat "
                addInputClass="light-border"
                placeHolder={"Add New Flower..."}
                name="types"
                style={{ height: "30px !important" }}
                value={newFlower.flower_name}
                noLable={true}
                onChageSetter={(a) => {
                  setNewFlower({...newFlower, flower_name: a.value});
                }}
              />
            </div>
            <div className="width-auto">
              <ButtonFlat
                  loading={editLoading}
                  spinLoading={true} 
                  addClass="width-100 thin-flat light-border"
                  value={edit? "Save":"Add"}
                  style={{
                    height: "45px !important",
                    marginTop: "3px",
                    boxShadow: "0px 3px 8px rgba(142, 139, 139, 0.1) !important",
                  }}
                  clickTo={() => {
                    editFlowers();
                }}
              />
            </div>
            <div className="width-auto ">
              <ButtonFlat
                addClass="width-100 thin-flat light-border color-red"
                value={"Cancel"}
                style={{
                  height: "45px !important",
                  width: "60px",
                  marginTop: "3px",
                  boxShadow: "0px 3px 8px rgba(142, 139, 139, 0.1) !important",
                }}
                clickTo={() => {
                  setEdit(false);
                  setNewFlower({flower_name: ""});
                }}
              />
            </div>
           
          </div>
          <div className="row width-100" style={{}}>
            {(flowers || []).map((each, index) => {
              return (
                <div className="col-md-6">
                  <TaggedList
                    editable={true}
                    text={each.flower_name}
                    onTagClick={() => {
                      setEdit(true);
                      setNewFlower(each);
                    }}
                    remove={(a) => {
            setAlert({
              type: "error",
              title: "Confirm Data Removal",
                        message: "Remove " + a + " From Flowers List?",
              confirmPrompt: true,
              callback: () => {
                deleteFlowers(each._id);
              },
            });
          }}
        />
                </div>
              );
            })}
          </div>
        </div>
  );
}