import { MdCancel } from "react-icons/md";
import { ButtonIcon } from "../../components";

export function TaggedList(props) {
  return (
      <div
        className={
          "relative center-hor space-between removable-tag-list xlight-box-shadow " +
          (props.editCCTypes ? "cc-edit" : "")
        }
        onClick={() => {
          props.onTagClick();
        }}
      >
        <div
          className={"text-left width-90 bold-1 "+(props.editable? "touchable color-gold": "")}
          style={{ marginRight: "30px", marginLeft: "10px" }}
        >
          {props.text}
        </div>
        <div
          style={{ width: "20px", padding: "0px 2px" }}
          className="center-hor float-absolute-right"
        >
          {props.editCCTypes ? (
            ""
          ) : (
            <ButtonIcon
              addClass="small red"
              loading={props.loading}
              icon={
                <MdCancel
                  className="icon mid light-red"
                  onClick={() => {
                    props.remove(props.text);
                  }}
                />
              }
            />
          )}
        </div>
      </div>
    );
  }