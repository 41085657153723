import { useState, useEffect } from "react";
import {
  TextInputField,
  DropDown,
  ButtonFlat,
  SummaryTable,
  TextInputFieldWithRelatedResult,
  LoadingDiv,
  LoadingWait,
  ProductPriceContentDisplay,
  PageLoading,
} from "../components";
import pic from "../images/flower1.png";
import axios from "axios";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { BASE_SERVER_URL, BASE_SPACE_URL } from "../sampleData";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdCancel, MdEdit } from "react-icons/md";

function ImageDisplay() {
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    axios
      .get(BASE_SERVER_URL + "/get-images")
      .then((response) => {
        console.log("images ", response.data.dat);
        setImageList(response.data.dat);
      })
      .catch((error) => console.error("Error fetching images:", error));
  }, []);

  return (
    <div>
      <h2>Uploaded Images:</h2>
      {imageList.map((image) => (
        <div key={image}>
          {/* <img src={`../../../uploads/${image}`} alt={image} width="200" height="200" /> */}
          <img
            src={`${BASE_SERVER_URL}/images/${image}`}
            alt={image}
            width="200"
            height="200"
          />
        </div>
      ))}
    </div>
  );
}

export function AddNewProductPages() {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileInput = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("image", selectedFile);

    fetch(BASE_SERVER_URL + "/upload-image", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Image upload successful:", data);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  return (
    <div>
      <input type="file" onChange={handleFileInput} />
      <button onClick={handleSubmit}>Upload</button>
      <ImageDisplay />
    </div>
  );
}

export function AddNewProductPage(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  document.title = props.title || "Ray`s Florist App";
  const [productSizeOptions, setProductSizeOptions] = useState([
    "One Size",
    "small",
    "medium",
    "large",
    "x-large",
    "2x-large",
  ]);
  const sizeMeteric = {
    wreath: {
      small: "50cm",
      medium: "55cm",
      large: "60cm",
      XL: "65cm",
      "2XL": "70cm",
    },
    hearts: { small: "60cm", medium: "65cm", large: "70cm", XL: "75cm" },
    cross: { small: "90cm", medium: "100cm", large: "110cm" },
  };
  const [flowers, setFlowers] = useState([]);
  const [productTypes, setProductTypes] = useState([]);

  const [flowerContentInputs, setFlowerContentsInputs] = useState({});
  const [sizesInput, setSizesInput] = useState({});
  const [sizesInputCurrent, setSizesInputCurrent] = useState({});
  const [savingLoading, setSavingLoading] = useState(false);
  const [imageSavingLoading, setImageSavingLoading] = useState(false);
  const [flowerSizeInput, setFlowerSizeInput] = useState("");

  const [productDescription, setProductDescription] = useState({});
  const [flowerContents, setFlowerContents] = useState({});
  const [sizes, setSizes] = useState({});
  const [imgUrl, setImgUrl] = useState(pic);
  const [images, setImages] = useState(null);
  const maxNumber = 69;

  const handleFileInput = (event) => {
    setImages(event.target.files[0]);
  };

  const handleSubmit = () => {
    setImageSavingLoading(true);
    const formData = new FormData();
    formData.append("image", images);

    axios
      .post(BASE_SERVER_URL + "/upload-image/flowers", formData)
      .then((data) => {
        setImageSavingLoading(false);
        // console.log("Image response:", data.data);
        if (data.data.status === "success") {
          console.log("Image upload successful:", data);
          setProductDescription((prev) => {
            let newObj = { ...prev };
            newObj.image = data.data.fileName;
            return newObj;
          });
        } else {
          toast.error("Error uploading file", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            theme: "light",
          });
        }
        setImageSavingLoading(false);
      })
      .catch((error) => {
        // console.error("Error uploading image:", error);
        setImageSavingLoading(false);
        toast.error("Error uploading file : " + error, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "light",
        });
      });
  };

  useEffect(() => {
    if (!flowerSizeInput) {
      setFlowerSizeInput(Object.keys(sizes)[0]);
    }
  }, [sizes]);

  useEffect(() => {
    console.log("deleting old changed ", flowerContents, sizes);
  }, [flowerContents]);

  useEffect(() => {
    if (props.editProduct) {
      populateState(props.productDetails);
    } else {
      setPageLoading(false);
    }

    if (
      Object.keys(flowers).length === 0 ||
      Object.keys(productTypes).length === 0
    ) {
      let url = BASE_SERVER_URL + "/settings/getAll";
      axios.get(url).then((doc) => {
        if (doc.data.status === "success") {
          setFlowers(JSON.parse(doc.data.response[0].flowers));
          setProductTypes(JSON.parse(doc.data.response[0].product_types));
        }
      });
    }
  }, []);

  const [pageLoading, setPageLoading] = useState(true);
  function populateState(data) {
    let url = BASE_SERVER_URL + "/products/getProduct/" + id;
    setPageLoading(true);
    axios
      .get(url)
      .then((response) => {
        setPageLoading(false);
        let data = response.data.response[0];
        // ...data
        setProductDescription({
          product_name: data.product_name,
          product_type: data.product_type,
          description: data.description,
          code: data.code,
          image: data.image,
          id: data._id,
        });
        setSizes(JSON.parse(data.sizes));
        setFlowerContents(JSON.parse(data.content));
      })
      .catch((err) => {
        setPageLoading(false);
      });
  }

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log("image set ", imageList, addUpdateIndex);
    setImages(imageList);
  };

  function submitHandler() {
    setSavingLoading(true);
    let prdct = { ...productDescription };
    delete prdct.id;
    let product = {
      ...prdct,
      content: JSON.stringify(flowerContents),
      sizes: JSON.stringify(sizes),
    };
    let url = props.editProduct
      ? BASE_SERVER_URL + "/products/modifyProduct"
      : BASE_SERVER_URL + "/products/addNew";
    let postData = props.editProduct
      ? { id: productDescription.id, updateData: product }
      : product;

    axios
      .post(url, postData)
      .then((response) => {
        if (response.data.status === "success") {
          setSavingLoading(false);
          toast.success(
            "Product " +
              (props.editProduct ? "updated" : "added") +
              " successfully",
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          navigate("/products");
        } else {
          toast.error(
            "Error " + (props.editProduct ? "Editing" : "Adding") + " Product",
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      })
      .catch((err) => {
        setSavingLoading(false);
        toast.error(
          "Error " + (props.editProduct ? "Editing" : "Adding") + " Product",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      });
  }
  if (pageLoading) {
    return <PageLoading noLogo={true} />;
  }
  return (
    <div className="product-page">
      <header className="large relative center-hor space-between">
        {!props.editProduct && "Add New Product"}
        {props.editProduct && "Modify Product"}
        <ButtonFlat
          style={{ background: "#eaeaea", width: "132px", height: "40px" }}
          value={props.editProduct ? "Save" : "Save"}
          loading={savingLoading}
          clickTo={submitHandler}
          addClass="form font-medium width-auto green-themed  padding-LR-15 light-box-shadow"
        />
      </header>
      <div className="contents">
        <div className="hor-line"></div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 relative">
            <div className="newcode font-medium width-auto float-right">
              {productDescription.code}
            </div>
            <div className="width-100">
              <header>Product Description</header>
              <TextInputField
                textArea={false}
                value={productDescription.product_name}
                onChageSetter={(a) => {
                  setProductDescription((prev) => {
                    return { ...prev, ...{ [a.name]: a.value } };
                  });
                }}
                name="product_name"
                addClass="stretch no-shaodw"
                addInputClass="light-border"
                placeHolder="Product Display Name"
              />
              <TextInputField
                textArea={true}
                value={productDescription.description}
                onChageSetter={(a) => {
                  setProductDescription((prev) => {
                    return { ...prev, ...{ [a.name]: a.value } };
                  });
                }}
                name="description"
                addClass="stretch no-shaodw"
                addInputClass="light-border"
                placeHolder="Product Description"
              />
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <TextInputFieldWithRelatedResult
                    value={productDescription.product_type}
                    searchList={productTypes || []}
                    search="product_types"
                    onChageSetter={(a) => {
                      setProductDescription((prev) => {
                        return { ...prev, ...{ product_type: a } };
                      });
                    }}
                    name="product_type"
                    addClass="width-100 no-shaodw light-border"
                    addInputClass=""
                    placeHolder="Product Type"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <TextInputField
                    value={productDescription.code}
                    onChageSetter={(a) => {
                      setProductDescription((prev) => {
                        return { ...prev, ...{ [a.name]: a.value } };
                      });
                    }}
                    name="code"
                    addClass="stretch no-shaodw"
                    addInputClass="light-border"
                    placeHolder="Product Code"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 relative">
            <header>Product Size and Price</header>
            <div className="row ">
              <div className="col-md-4 col-sm-6 ">
                <DropDown
                  options={productSizeOptions}
                  name="size"
                  value={sizesInputCurrent.size}
                  onChageSetter={(a) => {
                    setSizesInputCurrent((prev) => {
                      let edit = { editSize: false };
                      if (prev.size && prev.size !== a.value) {
                        edit = { editSize: true, oldSize: prev.size };
                      }
                      return {
                        ...prev,
                        ...{ size: a.value },
                        ...edit,
                      };
                    });
                  }}
                  placeHolder="Product Size"
                  addClass="no-shaodw light-border"
                />
              </div>
              <div className="col-md-4 col-sm-6">
                <TextInputField
                  type={"number"}
                  value={sizesInputCurrent.price}
                  onChageSetter={(a) => {
                    setSizesInputCurrent((prev) => {
                      return {
                        ...prev,
                        ...{ price: a.value },
                      };
                    });
                  }}
                  name="price"
                  addClass="stretch no-shadow"
                  addInputClass="light-border"
                  placeHolder="Price ($)"
                />
              </div>
              <div className="col-md-3 col-sm-6 center-hor float-right">
                <ButtonFlat
                  value="add"
                  clickTo={() => {
                    if (!sizesInputCurrent.size && !sizesInputCurrent.price) {
                      return false;
                    }
                    let sizeName = sizesInputCurrent.size;
                    let sizeName2 = sizesInputCurrent.editSize
                      ? sizesInputCurrent.oldSize
                      : sizesInputCurrent.size;

                    setFlowerContents((prev) => {
                      let oldObj = { ...prev };
                      oldObj[sizeName] = {
                        ...(oldObj[sizeName2] || {}),
                      };
                      if (sizesInputCurrent.editSize) {
                        delete oldObj[sizeName2];
                      }
                      return oldObj;
                    });
                    setSizes((prev) => {
                      let oldObj = { ...prev };
                      if (sizesInputCurrent.editSize) {
                        delete oldObj[sizeName2];
                      }
                      return {
                        ...oldObj,
                        ...{
                          [sizesInputCurrent.size]: sizesInputCurrent.price,
                        },
                      };
                    });
                    setSizesInputCurrent({ size: "", price: "" });
                  }}
                  addClass="form font-medium light-border"
                />
              </div>
            </div>
            <SummaryTable
              nameProperty={true}
              addClass="flat margin-top-10"
              data={sizes}
              addActionIcon={[
                {
                  icon: (val) => {
                    return (
                      <MdEdit
                        className="icon mid"
                        onClick={() => {
                          setSizesInputCurrent({
                            size: val,
                            price: sizes[val],
                          });
                          // setSizes((prev) => {
                          //   let newObj = { ...prev };
                          //   delete newObj[val];
                          //   return newObj;
                          // });
                        }}
                      />
                    );
                  },
                },
                {
                  icon: (val) => {
                    return (
                      <MdCancel
                        className="icon mid red"
                        onClick={() => {
                          let b = val;
                          setSizes((prev) => {
                            let a = { ...prev };
                            delete a[b];
                            return a;
                          });
                          setFlowerContents((prev) => {
                            let a = { ...prev };
                            delete a[b];
                            return a;
                          });
                        }}
                      />
                    );
                  },
                },
              ]}
            />
            <div className="width-100 center-hor">
              <header className="">Product Flower Content</header>
            </div>
            {Object.keys(sizes).length > 0 ? (
              <div className="row center-hor">
                <div className="col-md-4 col-sm-6">
                  <TextInputFieldWithRelatedResult
                    search="flowers"
                    value={flowerContentInputs.flower_name || ""}
                    searchList={flowers || []}
                    onChageSetter={(a) => {
                      setFlowerContentsInputs((prev) => {
                        return { ...prev, ...{ flower_name: a } };
                      });
                    }}
                    name="flower_name"
                    addClass="stretch light-border no-shadow"
                    placeHolder="Flower Name"
                  />
                </div>
                <div className="col-md-4 col-sm-6">
                  <TextInputField
                    type="number"
                    value={flowerContentInputs.amount || ""}
                    onChageSetter={(a) => {
                      setFlowerContentsInputs((prev) => {
                        return { ...prev, ...{ [a.name]: a.value } };
                      });
                    }}
                    name="amount"
                    addClass="stretch no-shadow"
                    addInputClass="light-border"
                    placeHolder="Quantity (Pcs)"
                  />
                </div>
                <div className="col-md-3 col-sm-6 float-right">
                  <ButtonFlat
                    value="add"
                    clickTo={() => {
                      if (
                        flowerContentInputs.flower_name &&
                        flowerContentInputs.amount
                      ) {
                        setFlowerContents((prev) => {
                          let oldObj = {};
                          let theSize =
                            flowerContentInputs.product_size || flowerSizeInput;
                          oldObj[theSize] = prev[theSize]
                            ? { ...prev[theSize] }
                            : {};

                          if (flowerContentInputs.edit) {
                            delete oldObj[theSize][
                              flowerContentInputs.old_flower_name
                            ];
                          }
                          oldObj[theSize][flowerContentInputs.flower_name] =
                            flowerContentInputs.amount;
                          return { ...prev, ...oldObj };
                        });
                        setFlowerContentsInputs({});
                      }
                    }}
                    addClass="form font-medium"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <ProductPriceContentDisplay
              newOne={true}
              changeActive={(a) => {
                setFlowerSizeInput(a);
              }}
              product_size={flowerSizeInput}
              data={{ content: flowerContents, price: sizes } || {}}
              changeFlowerContent={(a) => {
                setFlowerContentsInputs(a);
              }}
              removeFlower={(a) => {
                // return false;
                // console.log("flowers here 1 ", a, flowerContents);
                setFlowerContents((prev) => {
                  // console.log("flowers here ", a, prev, flowerContents);
                  let newObj = { ...prev };
                  delete newObj[a.size][a.name];
                  return newObj;
                });
              }}
            />
            {/* <SummaryTable nameProperty={true} addClass='flat margin-top-10' data={flowerContents[flowerSizeInput]?flowerContents[flowerSizeInput]:{}}
                                        addActionIcon={{icon:'cancel', action: (b)=>{setFlowerContents((prev)=>{let a = {...prev}; delete a[b]; return a})} }}
                                    /> */}
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 relative">
            <div className="row">
              <div className="col-lg-8 col-md-8 col-sm-8">
                <input
                  className="button flat width-100 inline-hor absolute-center bold-1 form font-medium width-stretch  padding-LR-15 light-box-shadow"
                  type="file"
                  onChange={handleFileInput}
                />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 center-hor">
                <ButtonFlat
                  style={{
                    background: "#eaeaea",
                    width: "100%",
                    height: "35px",
                  }}
                  value={"Upload Image"}
                  loading={imageSavingLoading}
                  clickTo={handleSubmit}
                  addClass="form font-medium width-stretch  padding-LR-15 light-box-shadow"
                />
              </div>
            </div>
            {productDescription.image ? (
              <img
                className=""
                style={{ width: "100%" }}
                src={BASE_SPACE_URL + "" + productDescription.image}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        {/* </form> */}
      </div>
    </div>
  );
}
