import React, { useEffect, useState } from "react";
import { LoadingWait } from "../components";
import { BASE_SPACE_URL } from "../sampleData";
import profile from "../images/profile.jpg";
import { FaUserTie, FaUser } from "react-icons/fa";

export function CollapsableUserDisplay(props) {
  const [active, setActive] = useState(false);
  console.log("user list ", props.detail);
  return (
    <div
      className={
        (active ? "active" : "") + " collapsable-user-display xlight-box-shadow"
      }
    >
      <div
        onClick={() => {
          setActive((prev) => !prev);
        }}
        className={
          (active ? "light-gold xlight-box-shadow" : "") +
          " center-hor width-100"
        }
      >
        <span>
          {props.detail.access === "Super Admin" ? (
            <FaUserTie className="icon XL padding-5 grey" />
          ) : (
            <FaUser className="icon XL padding-5 grey" />
          )}
        </span>
        {/* <img src={props.detail.thumb? BASE_SPACE_URL+''+props.detail.thumb : profile}/> */}
        <span style={{ marginLeft: "20px" }}>{props.detail.full_name}</span>
      </div>
      <div className={(!active ? "hidden" : "") + " user-detail"}>
        <div
          className="width-100 row center-hor"
          style={{ padding: "0px 10px" }}
        >
          <div className="col-md-4 col-sm-12 padding-10 text-center">
            <span className="width-auto font-small">User Name</span>
            <span className="width-auto font-medium">
              {props.detail.userName || "N/A"}
            </span>
          </div>
          <div className="col-md-4 col-sm-12 text-center">
            <span className="width-auto font-small">Password</span>
            <span className="width-auto font-medium">
              {props.detail.password || "N/A"}
            </span>
          </div>
          <div className="col-md-4 col-sm-12 text-center">
            <span className="width-auto font-small">Access</span>
            <span className="width-auto font-medium">
              {props.detail.access || "N/A"}
            </span>
          </div>
        </div>
        <div
          className={"width-100 center-hor"}
          style={{ background: "#eaeaea", marginBottom: "-6px" }}
        >
          <p
            className={
              "width-50 font-small absolute-center margin-0 " +
              (!props.deleteLoading ? "touchable" : "")
            }
            onClick={() => {
              props.deleteUser();
            }}
            style={{
              background: "#ffd3d3",
              color: props.deleteLoading ? "#dd7f88" : "red",
            }}
          >
            Delete User
          </p>
          <p
            className={
              "width-50 font-small absolute-center margin-0 " +
              (!props.deleteLoading ? "touchable" : "")
            }
            onClick={() => {
              props.editUser();
            }}
            style={{ color: props.deleteLoading ? "#dd7f88" : "black" }}
          >
            Edit
          </p>
        </div>
      </div>
    </div>
  );
}
